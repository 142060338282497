import axios from "axios";
import { BackEndAzureApi } from "../Config";
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";

const TarsServices = () => {

    const { getStorage } = useCreditRequestAuth();
    let creditRequestToken = getStorage("creditRequestToken")


    const SimulateApplication = async (body) => {

        let respose = await axios.post(BackEndAzureApi("CHAN/Applications/SimulateApplication"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const GetQuestions = async (body) => {

        let respose = await axios.post(BackEndAzureApi("CHAN/Evidente/GetQuestions"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const SetAnswers = async (body) => {

        let respose = await axios.post(BackEndAzureApi("CHAN/Evidente/SetAnswers"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    // const validateCustomer = async (body) => {

    //     let respose = await axios.post(BackEndAzureApi("Customer/validateCustomer"),
    //         body,
    //         {
    //             headers: {
    //                 'content-type': 'application/json',
    //                 "Authorization": "Bearer " + creditRequestToken
    //             }
    //         })
    //         .then(({ data }) => {
    //             return data;
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             return { error: 1 }
    //         })

    //     return respose;

    // };


    // const validateCustomer = async (DocumentType, Document) => {

    //     let respose = await axios.get(BackEndAzureApi("Customer/IsKnownCustomer?DocumentType=" + DocumentType + "&Document=" + Document),
    //         {
    //             headers: {
    //                 'content-type': 'application/json',
    //                 "Authorization": "Bearer " + creditRequestToken
    //             }
    //         })
    //         .then(({ data }) => {
    //             return data;
    //         })
    //         .catch((error) => {
    //             if (error.response) {
    //                 return error.response.data; // => the response payload 
    //             }
    //             return { error: 1 }
    //         })

    //     return respose;

    // };


    const GetPosById = async (IdPos, Amount) => {

        let respose = await axios.get(BackEndAzureApi("CHAN/POS/GetCommercialOfferByCustomer?IdPos=" + IdPos + "&IdInvitation=" + Amount),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const getCustomerById = async () => {

        let respose = await axios.get(BackEndAzureApi("CHAN/Customer/getCustomerByIdCustomer"),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };


    const GetApplicationsByCustomer = async () => {

        let respose = await axios.get(BackEndAzureApi("CHAN/Applications/GetApplicationsByCustomer"),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const GetOriginattionsByCustomer = async () => {

        let respose = await axios.get(BackEndAzureApi("CHAN/Originations/GetOriginattionsByCustomer"),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const newCustomer = async (body) => {

        let respose = await axios.post(BackEndAzureApi("CHAN/Customer/SaveCustomer"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { error: 1 }
            })

        return respose;

    };

    const createApplication = async (body) => {

        let respose = await axios.post(BackEndAzureApi("CHAN/Applications/SaveChangesApplication"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const runNewDecision = async (body) => {

        let respose = await axios.post(BackEndAzureApi("Decision/runNewDecision"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {

                return { error: 1 }
            })

        return respose;

    };


    const saveFilesCustomer = async (body) => {

        let respose = await axios.post(BackEndAzureApi("Files/SaveFilesCustomerFromJWT"),
            body,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };


    const SaveFilesManualValidationCustomerFromJWT = async (body) => {

        let respose = await axios.post(BackEndAzureApi("Files/SaveFilesManualValidationCustomerFromJWT"),
            body,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };


    const CalculateCreditLimit = async () => {

        let respose = await axios.get(BackEndAzureApi("CHAN/CreditLimit/CalculateCreditLimit"),

            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const GetSurvey = async () => {

        let respose = await axios.get(BackEndAzureApi("CHAN/Survey/GetSurvey"),

            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const SaveChangesSurvey = async (body) => {

        let respose = await axios.post(BackEndAzureApi("CHAN/Survey/SaveChangesSurvey"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { error: 1 }
            })

        return respose;

    };

    const GetInvitations = async () => {

        let respose = await axios.get(BackEndAzureApi("CHAN/Invitation/GetInvitations"),

            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };


    const CustomPaymentAmount = async (body) => {

        const respose = await axios.post(BackEndAzureApi("SKAL/Customer/CustomPaymentAmount"),
            body,
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };


    const GetBankList = async () => {

        const respose = await axios.get(BackEndAzureApi('SKAL/Customer/GetBankList'),
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    const GetPSEUrl = async (app, bank, originationReference, paymentReference, transactionValue, paymentDescription) => {

        const respose = axios.get(BackEndAzureApi(`SKAL/Customer/GetPSEUrl?App=${app}&FinancialInstitutionCode=${bank}&OriginationReference=${originationReference}&PaymentReference=${paymentReference}&TransactionValue=${transactionValue}&PaymentDescription=${paymentDescription}`),
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };


    const GetPayment = async (transactionId) => {

        const respose = axios.get(BackEndAzureApi('SKAL/Customer/GetPayment?transactionId=' + transactionId),
            {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }


            });
        return respose;
    };

    return {
        SimulateApplication, newCustomer, createApplication, runNewDecision,
        saveFilesCustomer, CalculateCreditLimit, GetInvitations, getCustomerById,
        GetPosById, GetSurvey, SaveChangesSurvey, GetQuestions, SetAnswers,
        GetApplicationsByCustomer, GetOriginattionsByCustomer, CustomPaymentAmount,
        SaveFilesManualValidationCustomerFromJWT, GetBankList, GetPSEUrl, GetPayment
    }
}

export default TarsServices;