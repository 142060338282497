import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import Home from './Components/Home';
import CreditRequestHome from './Components/CreditRequestHome';
import AppLogin from './Components/AppLogin';
import RequestChangePassword from './Components/RequestChangePassword';
import ResposeChangePassword from './Components/ResposeChangePassword';
import UserAutenticate from './Components/UserAutenticate';
import RequestStepOne from './Components/RequestStepOne';
import RequestStepTwo from './Components/RequestStepTwo';
import RequestStepThree from './Components/RequestStepThree';
import RequestStepFour from './Components/RequestStepFour';
import ManualValidation from './Components/ManualValidation';
import RequestStepFive from './Components/RequestStepFive';
import RequestStepSix from './Components/RequestStepSix';
import { ProtectedRoutesCreditRequest } from './Components/ProtectedRoutesCreditRequest';
import DigitalConfirmation from './Components/DigitalConfirmation';
import { CreditRequestProvider } from './Context/useCreditRequest';
import { CreditRequestUserProvider } from './Context/useCreditRequestAuth';
import Verification from './Components/Verification';
import SelfieVerificationLink from './Components/SelfieVerificationLink';
import SelfieVerification from './Components/SelfieVerification';
import Dashboard from './Components/Dashboard';
import CustomerSurvey from './Components/CustomerSurvey';
import ESign from './Components/ESign';
import QuestionsVerification from './Components/QuestionVerification';
import PaymentResult from './Components/PaymentResult';
import PaymentResultPSE from './Components/PaymentResultPSE';

function App() {
  return (
    <Router>
      <Routes>


        <Route path='/' element={
          <CreditRequestUserProvider>

            <CreditRequestProvider>
              <CreditRequestHome />
            </CreditRequestProvider>

          </CreditRequestUserProvider>
        } >
          <Route path="*" element={<RequestStepOne />} />
          {/* <Route path="/" element={<Navigate to="one" replace />} /> */}

          <Route path="/" element={<RequestStepOne />} />
          <Route path="Enrollment" element={<ProtectedRoutesCreditRequest><RequestStepTwo /></ProtectedRoutesCreditRequest>} />
          <Route path="DigitalConfirmation" element={<ProtectedRoutesCreditRequest><DigitalConfirmation /></ProtectedRoutesCreditRequest>} />
          <Route path="DigitalConfirmationLink" element={<ProtectedRoutesCreditRequest><RequestStepThree /></ProtectedRoutesCreditRequest>} />
          <Route path="Profile" element={<ProtectedRoutesCreditRequest><RequestStepFive /></ProtectedRoutesCreditRequest>} />
          <Route path="Verification" element={<ProtectedRoutesCreditRequest><Verification /></ProtectedRoutesCreditRequest>} />
          <Route path="SelfieVerificationLink" element={<ProtectedRoutesCreditRequest><SelfieVerificationLink /></ProtectedRoutesCreditRequest>} />
          <Route path="SelfieVerification" element={<ProtectedRoutesCreditRequest><SelfieVerification /></ProtectedRoutesCreditRequest>} />

          {/* <Route path="four" element={<ProtectedRoutesCreditRequest><RequestStepFour /></ProtectedRoutesCreditRequest>} /> */}
          <Route path="ManualValidation" element={<ProtectedRoutesCreditRequest><ManualValidation /></ProtectedRoutesCreditRequest>} />
          <Route path="Dashboard" element={<ProtectedRoutesCreditRequest><Dashboard /></ProtectedRoutesCreditRequest>} />

          <Route path="ApplicationRequest" element={<ProtectedRoutesCreditRequest><RequestStepSix /></ProtectedRoutesCreditRequest>} />
          {/* <Route path="CustomerSurvey" element={<ProtectedRoutesCreditRequest><CustomerSurvey /></ProtectedRoutesCreditRequest>} /> */}
          <Route path="eSign" element={<ProtectedRoutesCreditRequest><ESign /></ProtectedRoutesCreditRequest>} />
          <Route path="QuestionsVerification" element={<ProtectedRoutesCreditRequest><QuestionsVerification /></ProtectedRoutesCreditRequest>} />
          <Route path="paymentResult" element={<ProtectedRoutesCreditRequest><PaymentResult /></ProtectedRoutesCreditRequest>} />
          <Route path="PaymentResultPSE" element={<ProtectedRoutesCreditRequest><PaymentResultPSE /></ProtectedRoutesCreditRequest>} />
        </Route>


        <Route path='/login' element={<AppLogin />} />
        <Route path='/RequestChangePassword' element={<RequestChangePassword />} />
        <Route path='/ResposeChangePassword' element={<ResposeChangePassword />} />
        <Route path='/Autenticado' element={<UserAutenticate />} />
      </Routes>
    </Router>
  );
}

export default App;
