import React, { useEffect, useState } from "react";
import useForm from "../Hooks/useForm";
import '../StyleSheets/RequestStepOne.css'
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif'
import { useNavigate } from 'react-router-dom';
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import { useCreditRequest } from "../Context/useCreditRequest";
import { IsKnownCustomer } from '../Services/SoraService';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CustomSelect from "./CustomSelect";

const RequestStepOne = () => {


    const { clearRequest, logout, getStorage, setStorage } = useCreditRequestAuth();

    const { setLogoSize, getEC, setResendTimeSMS, setResendTimeEmail } = useCreditRequest();

    let request = getStorage("request") ?? {}
    let expiredTime = getStorage("expiredTime")

    const navigate = useNavigate();


    const initialForm = { idType: request.cusDocumentType ? request.cusDocumentType : "CCC", idNumber: request.cusDocument ? request.cusDocument : "" };
    const [sending, setSending] = useState(false);
    const [idTypes, setIdTypes] = useState([]);

    const MySwal = withReactContent(Swal);

    const validateForm = (form) => {

        let errors = {};

        if (!form.idType.trim()) { errors.idType = "El campo Tipo de documento es requerido" }
        if (!form.idNumber.trim()) { errors.idNumber = "El campo Número de documento es requerido" }


        return errors;

    };



    const { form, errors, handleChangeOnlyNumbers, handleSubmit, handleChangeSelectWithName, resetForm } = useForm(initialForm, validateForm);


    useEffect(() => {

        const isCookieEnabled = navigator.cookieEnabled;

        if (!isCookieEnabled) {

            MySwal.fire({
                html: "Esta pagina necesita cookies para su funcionamiento. Permite el uso de cookies y actualiza la pagina",
                showConfirmButton: false,
                icon: 'error',
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false
            });

            return;
        }

        //removeCookies();
        setLogoSize({ width: "320px", marginTop: "40px", marginBottom: "30px" })
        setSending(true);


        //let a = login({ userName: "Invitado", password: "Us3r1nv1t4d0", app: "TARS" });

        let b = getEC();

        //let c = getEnumsPointOfSellData();

        Promise.all([b]).then((values) => {
            setSending(false);


            setIdTypes(values[0].filter(el => el.EnumerationId === "cusEnumDocumentType")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label } }));

            if (Object.keys(request).length !== 0 && request.state !== 0 && new Date().getTime() < expiredTime) {


                if (request.state === 9) {
                    navigate("/Dashboard")
                } else {
                    Swal.fire({
                        html: `Atención!<br/><br/>Usted cuenta con un proceso actualmente guardado 💾 y vigente de registro en Meddipay para el documento ${request.cusDocumentTypeTemp} ${request.cusDocument} ${request.cusFirstName ? " - " + request.cusFirstName + " " + request.cusSecondName + " " + request.cusFirstSurname + " " + request.cusSecondSurname : ""}.<br/><br/>
                            ¿Desea continuar con el proceso donde lo dejo 🔙?`,
                        showDenyButton: true,
                        confirmButtonText: 'SI',
                        denyButtonText: 'NO',
                        icon: 'warning'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/Enrollment")
                        } else if (result.isDenied) {
                            logout();
                            clearRequest();
                            setResendTimeSMS(0)
                            setResendTimeEmail(0)
                            resetForm({ idType: "CCC", idNumber: "" });
                        }
                    })
                }


            }
            else if (!expiredTime || new Date().getTime() > expiredTime) {
                logout();
                clearRequest();
                setResendTimeSMS(0)
                setResendTimeEmail(0)
                resetForm({ idType: "CCC", idNumber: "" });
            }




        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    const submitForm = (form, error) => {

        form.idNumber = form.idNumber.trim();
        if (!error) {
            setSending(true);


            IsKnownCustomer(form.idType, form.idNumber).then(res => {
                setSending(false);
                if (res.status === 200) {
                    let data = JSON.parse(res.data.data);





                    if (!data.KnownCustomer) {

                        if (request && !request.updateCustomer) {
                            setStorage("creditRequestToken", data.token, { path: "/" })
                            setStorage("expiredTime", Date.parse(data.ExpiredTime), { path: "/" })
                        }


                        setStorage("request", {
                            ...request,
                            //cusType: "REG",
                            cusDocumentType: form.idType,
                            cusDocumentTypeTemp: idTypes.find(t => t.value === form.idType).name,

                            cusDocument: form.idNumber,
                            cusEmail: request.cusEmail && !request.cusEmail.includes("*") ? request.cusEmail : "",
                            cusPhone: request.cusPhone && !request.cusPhone.includes("#") ? request.cusPhone : "",
                            origin: 1,
                            state: 1
                        })

                        navigate("/Enrollment");

                    }
                    else {
                        setStorage("creditRequestToken", data.token, { path: "/" })
                        setStorage("expiredTime", Date.parse(data.ExpiredTime), { path: "/" })

                        setStorage("request", {
                            ...request,
                            cusType: "REG",
                            cusDocumentType: form.idType,
                            cusDocumentTypeTemp: idTypes.find(t => t.value === form.idType).name,

                            cusDocument: form.idNumber,
                            cusEmail: data.methods.find(el => el.type === "EMAIL")?.label,
                            cusPhone: data.methods.find(el => el.type === "SMS")?.label ?? data.methods.find(el => el.type === "WA")?.label,

                            idMethodEmail: data.methods.find(el => el.type === "EMAIL")?.id,
                            idMethodSMS: data.methods.find(el => el.type === "SMS")?.id,
                            idMethodWA: data.methods.find(el => el.type === "WA")?.id,

                            origin: 2,
                            state: 2
                        })

                        navigate("/DigitalConfirmation");
                    }
                }
                else {
                    MySwal.fire({
                        html: res.data.message ?? "ERROR",
                        icon: 'error'
                    })
                }


            })
            //navigate("/Enrollment");

            //navigate("/DigitalConfirmation");


            // validateCustomer(form.idType[0].value, form.idNumber).then(data => {
            //     console.log(data);
            //     //console.log(JSON.parse(data.data))
            // })


            // validateCustomer(body).then(res => {

            //     setSending(false);


            //     if (res.result) {
            //         setRequest({
            //             ...request,
            //             cusType: "REG",
            //             cusDocumentType: form.idType[0].value,
            //             idTypeTemp: form.idType,
            //             cusDocument: form.idNumber
            //         });


            //         navigate("/CreditRequest/two");
            //     }
            //     else {

            //         MySwal.fire({
            //             html: res.responseText,
            //             icon: 'error'
            //         });

            //     }



            // })





        }


    }




    return (
        <>
            <form onSubmit={(e) => handleSubmit(e, submitForm)}>
                <table id="step-one-table">
                    <tbody>
                        <tr>

                            <td style={{ textAlign: "center" }} colSpan={2}>
                                <br />
                                <br />
                                <br />
                                <h2>Identifícate</h2>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "center" }} colSpan={2}>Tanto para clientes nuevos como para clientes ya registrados</td>
                        </tr>
                        <tr>
                            <td>
                                {/* <Typeahead
                                    ref={idTypeRef}
                                    filterBy={(option, props) => {

                                        if (props.selected.length) {
                                            // Display all the options if there's a selection.
                                            return true;
                                        }
                                        // Otherwise filter on some criteria.
                                        return option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
                                    }}
                                    id="typeahead-idType"
                                    name="idType"
                                    labelKey="name"
                                    onChange={e => handleChangeTypeahead(e, "idType")}
                                    options={idTypes}
                                    placeholder="Tipo de documento"
                                    selected={form.idType}
                                    onBlur={() => handleBlurTypeahead(form.idType, "idType", idTypeRef)}
                                    renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                        return (
                                            <Hint>
                                                <FloatingLabel controlId="floatingLabel" label="Tipo de documento">
                                                    <Form.Control
                                                        {...inputProps}
                                                        ref={(node) => {
                                                            inputRef(node);
                                                            referenceElementRef(node);
                                                        }}
                                                    />
                                                </FloatingLabel>
                                            </Hint>
                                        );
                                    }}
                                    inputProps={errors.idType && {
                                        className: 'is-invalid',
                                    }}
                                >
                                </Typeahead> */}
                                {/* <div className="form-floating">
                                    <select name="idType" className={`form-control ${errors.idType && "is-invalid"}`} id="idType" value={form.idType} onChange={handleChangeSelectWithName}>
                                        <option disabled value="" style={{ display: "none" }}></option>
                                        {idTypes.map(it => {
                                            return <option key={it.value} value={it.value}>{it.name}</option>
                                        })}


                                    </select>
                                    <label htmlFor="idType" className={form.idType ? "floating-label-ro2" : "floating-label-ro1"}>Tipo de documento</label>
                                </div> */}
                                <CustomSelect name="idType" value={form.idType} options={idTypes} text="Tipo de documento" error={errors.idType} handleChangeSelect={handleChangeSelectWithName} />

                            </td>

                        </tr>
                        <tr>
                            <td>
                                <div className="form-floating">
                                    <input id="idNumber" type="text" name="idNumber" value={form.idNumber} onChange={handleChangeOnlyNumbers} className={`form-control ${errors.idNumber && "is-invalid"}`} placeholder="Número de documento" autoComplete="off" />
                                    <label htmlFor="idNumber">Número de documento</label>
                                </div>

                            </td>
                        </tr>

                        {/* <tr>
                            <td colSpan={2}>

                                <Typeahead
                                    ref={doctorRef}
                                    clearButton
                                    id="typeahead-doctor"
                                    name="doctor"
                                    labelKey="name"
                                    onChange={e => handleChangeTypeahead(e, "doctor")}
                                    options={doctors}
                                    placeholder="Doctor"
                                    selected={form.doctor}
                                    renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                        return (
                                            <Hint>
                                                <FloatingLabel controlId="floatingLabel" label="Doctor">
                                                    <Form.Control
                                                        {...inputProps}
                                                        ref={(node) => {
                                                            inputRef(node);
                                                            referenceElementRef(node);
                                                        }}
                                                    />
                                                </FloatingLabel>
                                            </Hint>
                                        );
                                    }}
                                    inputProps={errors.doctor && {
                                        className: 'is-invalid',
                                    }}
                                >
                                </Typeahead>

                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "center" }} colSpan={2}>
                                {doctorAddress && <img className='imgAddress' src={imgAddress} alt='imgAddress'></img>}
                                <span style={{ color: "gray", fontSize: "15px" }}>{doctorAddress}</span>
                            </td>
                        </tr>

                        <tr>

                            <td colSpan={2}>
                                <div className="form-floating">
                                    <input type="text" name="treatment" value={form.treatment} onChange={handleChange} placeholder="Tratamiento" className={`form-control ${errors.treatment && "is-invalid"}`} autoComplete="off" />
                                    <label htmlFor="treatment">Tratamiento</label>
                                </div>
                            </td>

                        </tr>

                        <tr>
                            <td colSpan={2}>
                                <div className="form-floating">

                                    <MaskedInput mask={currencyMask} name="treatmentCost" value={form.treatmentCost} onChange={handleChange} placeholder="Costo del tratamiento" className={`form-control ${errors.treatmentCost && "is-invalid"}`} autoComplete="off"></MaskedInput >
                                    <label htmlFor="treatmentCost">Costo del tratamiento</label>
                                </div>
                                {doctorAddress && posMaxPurchaseAmt && posMinPurchaseAmt && <p style={{ fontSize: "12px", color: "gray" }}>Min: {moneyLocale.format(posMinPurchaseAmt)},  Max: {moneyLocale.format(posMaxPurchaseAmt)}</p>}
                            </td>
                        </tr> */}

                        <tr>
                            <td style={{ textAlign: "center", padding: "10px" }} colSpan={2}>
                                <input type="submit" value="Continuar" disabled={sending} className="btn btn-mp" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {sending &&
                                    (
                                        <div className="my-loader">
                                            <div className="my-loader-container">
                                                <img src={imgLoader} alt="loader"></img>

                                            </div>
                                        </div>
                                    )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>



        </>

    );
}


// function upFirstLetter(str) {
//     const arr = str.split(" ");
//     for (var i = 0; i < arr.length; i++) {
//         arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
//     }
//     const str2 = arr.join(" ");
//     return str2;
// };

// const defaultMaskOptions = {
//     prefix: '$',
//     suffix: '',
//     includeThousandsSeparator: true,
//     thousandsSeparatorSymbol: '.',
//     allowDecimal: true,
//     decimalSymbol: ',',
//     decimalLimit: 2, // how many digits allowed after the decimal
//     //   integerLimit: 7, // limit length of integer numbers
//     allowNegative: false,
//     allowLeadingZeroes: false,
// }

//const currencyMask = createNumberMask(defaultMaskOptions)

export default RequestStepOne;