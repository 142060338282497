import React, { useState, useEffect } from 'react'
import Webcam from 'react-webcam'
import '../StyleSheets/ManualValidation.css'
import imgSelfie from '../Images/selfie.png'
import imgManualValidation from '../Images/manual-validation.png'
import imgPendingManualValidation from '../Images/pending-manual-validation.png'
import imgFrontPicture from '../Images/frontid.png'
import imgBackPicture from '../Images/backid.png'
import { Link, useNavigate } from 'react-router-dom'
import useModalCreditRequest from '../Hooks/useModalCreditRequest'
import ModalCreditRequest from './ModalCreditRequest'
import { Dropdown } from 'react-bootstrap'
import TarsServices from '../Services/TarsServices'
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useCreditRequestAuth } from '../Context/useCreditRequestAuth'
import dayjs from 'dayjs';

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const resolutionWidth = 1080;
const resolutionHeight = 1080;

const videoConstraints = {
    // width: resolutionWidth,//{ ideal: resolutionWidth },
    // height: resolutionHeight,//{ ideal: resolutionHeight },
    // aspectRatio: 1,
    width: resolutionWidth,
    height: resolutionHeight,
    facingMode: FACING_MODE_USER,
}




const ManualValidation = () => {

    const [isOpenModalPicture, openModalPicture, closeModalPicture] = useModalCreditRequest(false);
    const [isOpenModalFile, openModalFile, closeModalFile] = useModalCreditRequest(false);
    const navigate = useNavigate();
    const [selfiePicture, setSelfiePicture] = useState("");
    const [showDivSelfiePicture, setShowDivSelfiePicture] = useState(false);
    const [sending, setSending] = useState(false);
    const [idFrontPicture, setIdFrontPicture] = useState("");
    const [showDivIdFrontPicture, setShowDivIdFrontPicture] = useState(false);

    const [idBackPicture, setIdBackPicture] = useState("");
    const [showDivIdBackPicture, setShowDivIdBackPicture] = useState(false);
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

    const [activeCamera, setActiveCamera] = useState(false);
    const imgFileRef = React.useRef();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const webcamRef = React.useRef(null);

    const { saveFilesCustomer, SaveFilesManualValidationCustomerFromJWT } = TarsServices();
    const MySwal = withReactContent(Swal);
    const { cookies, setCookies, logout, clearRequest, getStorage, setStorage } = useCreditRequestAuth();

    // const [videoConstraints, setVideoConstraints] = useState({});

    // const [tempText, settempText] = useState("");

    let request = getStorage("request");

    useEffect(() => {

        MySwal.fire({
            html: `<b>Para el proceso manual ten en cuenta las siguientes indicaciones a la hora de tomarte la selfie 🤳</b>
            <br/>
            <br/>

            <ul style="text-align:left;font-size: 15px;">
            <li>No usar tapabocas, gafas, gorras ni audífonos</li>
            <li>La foto debe estar enfocada</li>
            <li>Sostén tu documento firmemente debajo de tu rostro, tal como te mostramos a continuación</li>
            </ul>
            <img style="width:250px" src="` + String(imgManualValidation) + `"/>`,
            confirmButtonText: "Entendido"
        })

        // let features = {
        //     video: {
        //         width: { ideal: 4000 },
        //         height: { ideal: 4000 }
        //     }
        // };

        // navigator.mediaDevices
        //     .getUserMedia(features).then(display => {
        //         // console.log(display.getVideoTracks())
        //         let size = 1080;

        //         display.getVideoTracks().forEach(element => {


        //             let settings = element.getSettings();
        //             console.log(settings)
        //             settempText(tempText + JSON.stringify(settings))
        //             let width = settings.width;
        //             let height = settings.height;

        //             let tempSize = height < width ? height : width;

        //             if (size > tempSize)
        //                 size = tempSize

        //         });

        //         setVideoConstraints({
        //             width: size,
        //             height: size,
        //             facingMode: FACING_MODE_USER,
        //         })
        //     });



        // Returns a sequence of MediaStreamTrack objects  
        // representing the video tracks in the stream 



    }, []);

    const capture = React.useCallback((e, type) => {
        const pictureSrc = webcamRef.current.getScreenshot()
        if (type === "selfie")
            setSelfiePicture(pictureSrc)
        if (type === "front")
            setIdFrontPicture(pictureSrc)
        if (type === "back")
            setIdBackPicture(pictureSrc)
    });


    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}

        </div>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >

                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children.toLowerCase().startsWith(value),
                        )}
                    </ul>
                </div>
            );
        },
    );


    const switchCamera = React.useCallback(() => {

        setFacingMode(
            prevState => {
                return prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
            }

        );
    }, []);

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    const validatePictures = async () => {
        setSending(true);
        let image1;
        let image2;
        let image3;

        if (typeof selfiePicture === "object") {
            image1 = selfiePicture;
        }
        else {
            image1 = new File([b64toBlob(selfiePicture.split(",")[1], "image/jpeg")], `selfie_device_${dayjs().format("YYYYMMDD_HH_mm_ss")}.jpg`, {
                type: "image/jpeg"
            });
        }

        if (typeof idFrontPicture === "object") {
            image2 = idFrontPicture;
        }
        else {
            image2 = new File([b64toBlob(idFrontPicture.split(",")[1], "image/jpeg")], `front_device_${dayjs().format("YYYYMMDD_HH_mm_ss")}.jpg`, {
                type: "image/jpeg"
            });
        }

        if (typeof idBackPicture === "object") {
            image3 = idBackPicture;
        }
        else {
            image3 = new File([b64toBlob(idBackPicture.split(",")[1], "image/jpeg")], `back_device_${dayjs().format("YYYYMMDD_HH_mm_ss")}.jpg`, {
                type: "image/jpeg"
            });
        }


        var bodyFormData1 = new FormData();
        // var bodyFormData2 = new FormData();
        // var bodyFormData3 = new FormData();


        bodyFormData1.append('Selfie.File', image1);
        bodyFormData1.append('CedulaFront.File', image2);
        bodyFormData1.append('CedulaBack.File', image3);

        bodyFormData1.append('Cedula', request.cusDocument);
        bodyFormData1.append('cusFullName', `${request.cusFirstName} ${request.cusSecondName} ${request.cusFirstSurname} ${request.cusSecondSurname}`);
        bodyFormData1.append('Email', request.cusEmail);

        SaveFilesManualValidationCustomerFromJWT(bodyFormData1).then(res => {

            if (res.status === 200) {
                MySwal.fire({
                    html: `<b>Estamos validando tu información</b>
                                <br/>
                                <br/>
                                <img style="width:100px" src="` + String(imgPendingManualValidation) + `"/>
                                <br/>
                                <br/>
                                <p>En estos momentos nuestro equipo se encuentra validando la información que acabas de suministrarnos, en breve nos comunicaremos contigo para continuar con tu proceso.</p>
                                `,
                    confirmButtonText: "Entendido",
                    allowEnterKey: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false
                })

                logout();
                clearRequest();
            } else {

                MySwal.fire({
                    html: res.message,
                    icon: 'error'
                })

            }
        })

        // bodyFormData1.append('IdCustomer', request.idCustomer);
        // bodyFormData2.append('IdCustomer', request.idCustomer);
        // bodyFormData3.append('IdCustomer', request.idCustomer);

        // bodyFormData1.append('FileType', "PIC");
        // bodyFormData2.append('FileType', "CCC");
        // bodyFormData3.append('FileType', "CCC");

        // bodyFormData1.append('File', image1);
        // bodyFormData2.append('File', image2);
        // bodyFormData3.append('File', image3);

        // let file1 = saveFilesCustomer(bodyFormData1);
        // let file2 = saveFilesCustomer(bodyFormData2);
        // let file3 = saveFilesCustomer(bodyFormData3);

        // Promise.all([file1, file2, file3]).then((values) => {
        //     setSending(false);

        //     if (values[0].status === 200 && values[1].status === 200 && values[2].status === 200) {

        //         MySwal.fire({
        //             html: `<b>Estamos validando tu información</b>
        //             <br/>
        //             <br/>
        //             <img style="width:100px" src="` + String(imgPendingManualValidation) + `"/>
        //             <br/>
        //             <br/>
        //             <p>En estos momentos nuestro equipo se encuentra validando la información que acabas de suministrarnos, en breve nos comunicaremos contigo para continuar con tu proceso.</p>
        //             `,
        //             confirmButtonText: "Entendido"
        //         })

        //         logout();
        //         clearRequest();
        //     }
        //     else {


        //         MySwal.fire({
        //             html: values[0].status !== 200 ? values[0].message : (values[1].status !== 200 ? values[1].message : (values[2].message)),
        //             icon: 'error'
        //         })

        //     }

        // });

        // setRequest({
        //     ...request,
        //     identityValidated: true
        // });

        // navigate("/CreditRequest/five");
    }

    return (
        <>

            <table id="manual-validation-table">
                <tbody>
                    <tr>
                        <td style={{ textAlign: "center", color: "gray" }} colSpan={2}>
                            <h3>4/4</h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="divPicture">

                                <div onClick={!selfiePicture ? (e) => {
                                    setFacingMode(FACING_MODE_USER);
                                    setShowDivSelfiePicture(true);
                                    setShowDivIdFrontPicture(false);
                                    setShowDivIdBackPicture(false);
                                    setActiveCamera(true);
                                    openModalPicture();
                                } : undefined}>
                                    <img src={!selfiePicture ? imgSelfie : typeof selfiePicture === "object" ? URL.createObjectURL(selfiePicture) : selfiePicture} className="imgPicture" style={{ width: !selfiePicture ? "70px" : "" }}></img>


                                    {selfiePicture && (<i className="bi bi-x manual-validation-icon" onClick={(e) => {
                                        e.stopPropagation();
                                        imgFileRef.current.value = "";
                                        setSelfiePicture("");
                                    }

                                    }></i>)}
                                    <br />
                                    {!selfiePicture ? "Selfie" : ""}
                                </div>
                                {/* <Dropdown onClick={!selfiePicture ? (e) => {
                                    setFacingMode(FACING_MODE_USER);
                                    setShowDivSelfiePicture(true);
                                    setShowDivIdFrontPicture(false);
                                    setShowDivIdBackPicture(false);
                                    setActiveCamera(true);
                                    openModalPicture();
                                } : undefined}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        <img src={!selfiePicture ? imgSelfie : typeof selfiePicture === "object" ? URL.createObjectURL(selfiePicture) : selfiePicture} className="imgPicture" style={{ width: !selfiePicture ? "70px" : "" }}></img>


                                        {selfiePicture && (<i className="bi bi-trash manual-validation-icon" onClick={(e) => {
                                            e.stopPropagation();
                                            imgFileRef.current.value = "";
                                            setSelfiePicture("");
                                        }

                                        }></i>)}
                                        <br />
                                        {!selfiePicture ? "Selfie" : ""}
                                    </Dropdown.Toggle> */}

                                {/* <Dropdown.Menu as={CustomMenu}>
                                         <Dropdown.Item eventKey="1" onClick={(e) => {
                                            setShowDivSelfiePicture(true);
                                            setShowDivIdFrontPicture(false);
                                            setShowDivIdBackPicture(false);
                                            openModalFile();
                                        }}>Cargar imagen</Dropdown.Item>

                                        <Dropdown.Item eventKey="1" onClick={(e) => {
                                            setFacingMode(FACING_MODE_USER);
                                            setShowDivSelfiePicture(true);
                                            setShowDivIdFrontPicture(false);
                                            setShowDivIdBackPicture(false);
                                            setActiveCamera(true);
                                            openModalPicture();
                                        }}>Tomar foto</Dropdown.Item>

                                    </Dropdown.Menu> */}
                                {/* </Dropdown> */}
                            </div>

                            {/* <br />
                            {selfiePicture && (<><img src={typeof selfiePicture === "object" ? URL.createObjectURL(selfiePicture) : selfiePicture} className="imgPicture"></img>

                                <i className="bi bi-trash manual-validation-icon" onClick={(e) => {
                                    imgFileRef.current.value = "";
                                    setSelfiePicture("");
                                }

                                }></i>

                            </>)} */}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="divPicture">
                                <div onClick={!idFrontPicture ? (e) => {
                                    setFacingMode(FACING_MODE_ENVIRONMENT);
                                    setShowDivSelfiePicture(false);
                                    setShowDivIdFrontPicture(true);
                                    setShowDivIdBackPicture(false);
                                    setActiveCamera(true);
                                    openModalPicture();
                                } : undefined}>
                                    <img src={!idFrontPicture ? imgFrontPicture : typeof idFrontPicture === "object" ? URL.createObjectURL(idFrontPicture) : idFrontPicture} className="imgPicture" ></img>


                                    {idFrontPicture && (< i className="bi bi-x manual-validation-icon" onClick={(e) => {
                                        e.stopPropagation();
                                        imgFileRef.current.value = "";
                                        setIdFrontPicture("");
                                    }

                                    }></i>)}
                                    <br />
                                    {!idFrontPicture ? "Frontal cedula" : ""}
                                </div>
                                {/* <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        <img src={!idFrontPicture ? imgFrontPicture : typeof idFrontPicture === "object" ? URL.createObjectURL(idFrontPicture) : idFrontPicture} className="imgPicture" ></img>


                                        {idFrontPicture && (< i className="bi bi-trash manual-validation-icon" onClick={(e) => {
                                            e.stopPropagation();
                                            imgFileRef.current.value = "";
                                            setIdFrontPicture("");
                                        }

                                        }></i>)}
                                        <br />
                                        {!idFrontPicture ? "Frontal cedula" : ""}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu}>
                                       
                                        <Dropdown.Item eventKey="1" onClick={(e) => {
                                            setFacingMode(FACING_MODE_ENVIRONMENT);
                                            setShowDivSelfiePicture(false);
                                            setShowDivIdFrontPicture(true);
                                            setShowDivIdBackPicture(false);
                                            setActiveCamera(true);
                                            openModalPicture();
                                        }}>Tomar foto</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                            </div>
                            {/* <br />
                            {idFrontPicture && (<><img src={typeof idFrontPicture === "object" ? URL.createObjectURL(idFrontPicture) : idFrontPicture} className="imgPicture"></img>
                            
                            <i className="bi bi-trash manual-validation-icon" onClick={(e) => {
                                imgFileRef.current.value = "";
                                setIdFrontPicture("");
                            }

                            }></i></>)} */}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="divPicture">
                                <div onClick={!idBackPicture ? (e) => {
                                    setFacingMode(FACING_MODE_ENVIRONMENT);
                                    setShowDivSelfiePicture(false);
                                    setShowDivIdFrontPicture(false);
                                    setShowDivIdBackPicture(true);
                                    setActiveCamera(true);
                                    openModalPicture();
                                } : undefined}>
                                    <img src={!idBackPicture ? imgBackPicture : typeof idBackPicture === "object" ? URL.createObjectURL(idBackPicture) : idBackPicture} className="imgPicture"></img>

                                    {idBackPicture && (<i className="bi bi-x manual-validation-icon" onClick={(e) => {
                                        e.stopPropagation();
                                        imgFileRef.current.value = "";
                                        setIdBackPicture("");
                                    }

                                    }></i>)}

                                    <br />
                                    {!idBackPicture ? "Reverso cedula" : ""}
                                </div>
                                {/* <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        <img src={!idBackPicture ? imgBackPicture : typeof idBackPicture === "object" ? URL.createObjectURL(idBackPicture) : idBackPicture} className="imgPicture"></img>

                                        {idBackPicture && (<i className="bi bi-trash manual-validation-icon" onClick={(e) => {
                                            e.stopPropagation();
                                            imgFileRef.current.value = "";
                                            setIdBackPicture("");
                                        }

                                        }></i>)}

                                        <br />
                                        {!idBackPicture ? "Reverso cedula" : ""}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu}>
                                     
                                        <Dropdown.Item eventKey="1" onClick={(e) => {
                                            setFacingMode(FACING_MODE_ENVIRONMENT);
                                            setShowDivSelfiePicture(false);
                                            setShowDivIdFrontPicture(false);
                                            setShowDivIdBackPicture(true);
                                            setActiveCamera(true);
                                            openModalPicture();
                                        }}>Tomar foto</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                            </div>
                            {/* <br />
                            {idBackPicture && (<><img src={typeof idBackPicture === "object" ? URL.createObjectURL(selfiePicture) : idBackPicture} className="imgPicture"></img>
                            
                            
                            <i className="bi bi-trash manual-validation-icon" onClick={(e) => {
                                imgFileRef.current.value = "";
                                setIdBackPicture("");
                            }

                            }></i>
                            </>)} */}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <Link to="/SelfieVerification" onClick={() => {
                                    setStorage("request", {
                                        ...request, state: 5
                                    })
                                }}>
                                    <button type="button" className="btn btn-light" style={{ marginRight: "20px" }}>Regresar</button>
                                </Link>
                                {selfiePicture && idFrontPicture && idBackPicture && (
                                    <button className="btn btn-primary" onClick={validatePictures}>Continuar</button>
                                )}

                            </div>
                        </td>
                    </tr>
                </tbody>
            </table >
            {sending &&
                (
                    <div className="my-loader">
                        <div className="my-loader-container">
                            <img src={imgLoader} alt="loader"></img>

                        </div>
                    </div>
                )
            }
            <ModalCreditRequest isOpen={isOpenModalFile} closeModal={(e) => { closeModalFile(); imgFileRef.current.value = ""; }}>
                <div className="manual-validation-cam">
                    {showDivSelfiePicture && selfiePicture && <img src={typeof selfiePicture === "object" ? URL.createObjectURL(selfiePicture) : selfiePicture} className="tempImgPicture"></img>}
                    {showDivIdFrontPicture && idFrontPicture && <img src={typeof idFrontPicture === "object" ? URL.createObjectURL(idFrontPicture) : idFrontPicture} className="tempImgPicture"></img>}
                    {showDivIdBackPicture && idBackPicture && <img src={typeof idBackPicture === "object" ? URL.createObjectURL(idBackPicture) : idBackPicture} className="tempImgPicture"></img>}

                    <input
                        ref={imgFileRef}
                        type="file"
                        name="myImage"
                        onChange={(event) => {
                            if (showDivSelfiePicture)
                                setSelfiePicture(event.target.files[0]);
                            if (showDivIdFrontPicture)
                                setIdFrontPicture(event.target.files[0]);
                            if (showDivIdBackPicture)
                                setIdBackPicture(event.target.files[0]);
                        }}
                        accept="image/png, image/gif, image/jpeg"
                    />
                </div>
            </ModalCreditRequest>


            <ModalCreditRequest isOpen={isOpenModalPicture} closeModal={(e) => {
                setShowDivSelfiePicture(false);
                setShowDivIdFrontPicture(false);
                setShowDivIdBackPicture(false);
                setActiveCamera(false);
                closeModalPicture();
            }}>
                <div style={{ margin: "0 auto" }}>
                    <div className="manual-validation-cam">

                        <div>
                            <div style={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}>

                                <div style={{ width: "100%", }}>
                                    <h3 style={{ color: "gray", marginBottom: "0" }}>
                                        {showDivSelfiePicture ? "Foto selfie" : showDivIdFrontPicture ? "Foto frontal documento" : "Foto reverso documento"}
                                    </h3>
                                    <i className="bi bi-x" style={{ fontSize: "35px", position: "absolute", right: "5px", top: "7px" }} onClick={closeModalPicture}></i>
                                </div>



                            </div>
                            {
                                showDivSelfiePicture && selfiePicture ?
                                    (
                                        <>
                                            <img src={selfiePicture} className="tempImgPicture" />
                                            <br />
                                            <br />
                                            <button
                                                style={{ backgroundColor: "#d3d4d5" }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setSelfiePicture("")
                                                    setActiveCamera(true)
                                                }}
                                                className="btn btn-light">
                                                Recapturar
                                            </button>
                                            <br />
                                            <br />
                                            <button style={{ backgroundColor: "#79d853" }} className="my-modal-close btn btn-light" onClick={closeModalPicture}>Aceptar</button>
                                        </>
                                    ) :
                                    showDivIdFrontPicture && idFrontPicture ? (
                                        <>
                                            <img src={idFrontPicture} className="tempImgPicture" />
                                            <br />
                                            <br />
                                            <button
                                                style={{ backgroundColor: "#d3d4d5" }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setIdFrontPicture("")
                                                    setActiveCamera(true)
                                                }}
                                                className="btn btn-light">
                                                Recapturar
                                            </button>
                                            <br />
                                            <br />
                                            <button style={{ backgroundColor: "#79d853" }} className="my-modal-close btn btn-light" onClick={closeModalPicture}>Aceptar</button>
                                        </>

                                    ) :
                                        showDivIdBackPicture && idBackPicture ? (
                                            <>
                                                <img src={idBackPicture} className="tempImgPicture" />
                                                <br />
                                                <br />
                                                <button
                                                    style={{ backgroundColor: "#d3d4d5" }}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setIdBackPicture("")
                                                        setActiveCamera(true)
                                                    }}
                                                    className="btn btn-light">
                                                    Recapturar
                                                </button>
                                                <br />
                                                <br />
                                                <button style={{ backgroundColor: "#79d853" }} className="my-modal-close btn btn-light" onClick={closeModalPicture}>Aceptar</button>
                                                {/* <i class="bi bi-arrow-left-circle manual-validation-icon" onClick={backIdBackPicture}></i> */}

                                            </>

                                        ) : (activeCamera) ?
                                            (<>

                                                <div style={{ width: screenWidth < 600 ? screenWidth : screenWidth * 0.3, height: screenWidth < 600 ? screenWidth : screenWidth * 0.3, position: "relative", margin: "0 auto" }} className="cam-container">
                                                    <Webcam
                                                        audio={false}
                                                        width="100%"
                                                        height="100%"
                                                        ref={webcamRef}
                                                        forceScreenshotSourceSize={true}
                                                        screenshotFormat="image/jpeg"
                                                        videoConstraints={{
                                                            ...videoConstraints,
                                                            facingMode
                                                        }}
                                                    />
                                                    <div style={{ cursor: "pointer", position: "absolute", left: "0%", right: "0%", zIndex: "999", bottom: "5%", color: "red", fontWeight: "bold", fontSize: "50px" }}>
                                                        <i
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                capture(e, showDivSelfiePicture ? "selfie" : showDivIdFrontPicture ? "front" : "back")
                                                            }}
                                                            className="bi bi-record-circle"

                                                        >

                                                        </i>

                                                        <i style={{ position: "absolute", right: "20px", cursor: "pointer", color: "white", fontWeight: "bold", fontSize: "50px" }} className="bi bi-phone-flip" onClick={switchCamera} ></i>
                                                    </div>



                                                </div>

                                                
                                                <br />



                                            </>) : <></>
                            }
                        </div>

                    </div>


                </div>
            </ModalCreditRequest>




        </>

    );
}

export default ManualValidation;