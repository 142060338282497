import { createContext, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { GetToken } from '../Services/SoraService';
//import api from '../services/api'

const UserContext = createContext();

export const CreditRequestUserProvider = ({ children }) => {
    const navigate = useNavigate();
    const [cookies, setCookies] = useCookies();

    let interval = null;

    useEffect(() => {
        interval = setInterval(() => {

            let expiredTime = localStorage.getItem("expiredTime")

            if (expiredTime) {

                if (expiredTime < new Date().getTime()) {
                 
                    logout();
                    clearRequest();
                }
            }


        }, 1000 * 5);

    }, []);


    useEffect(() => {

        return () => {
            clearInterval(interval);
        };
    }, []);


    const login = (body) => {

        return new Promise(function (resolve, reject) {

            GetToken(body).then(res => {

                if (res.result === 200) {
                    setStorage("creditRequestToken", res.data.token, { path: "/" });
                    setStorage("expiredTime", Date.parse(res.data.expiredTime), { path: "/" })
                    resolve({ "msg": "ok" })
                }
                else {
                    resolve({ "msg": "error" })
                }

            });

        })


    };

    const logout = () => {
        removeCookies();
        navigate("/");
    };


    const setStorage = (name, item) => {
        localStorage.setItem(name, JSON.stringify(item));
    }
    const getStorage = (name) => {
        let item = localStorage.getItem(name);
        return JSON.parse(item);
    }
    const removeStorage = (name) => {
        localStorage.removeItem(name);
    }

    const removeCookies = () => {
        localStorage.removeItem("creditRequestToken");
        localStorage.removeItem("expiredTime");
    }

    const clearRequest = () => {
        removeStorage("request")
    }

    const value = {
        cookies,
        login,
        logout,
        setCookies,
        clearRequest,
        setStorage,
        getStorage,
        removeStorage
    }

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )
};

export const useCreditRequestAuth = () => {
    return useContext(UserContext)
};