import React, { useEffect, useRef, useState } from "react";
import useForm from "../Hooks/useForm";
import '../StyleSheets/RequestStepTwo.css'
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif'
import { Button, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useCreditRequest } from "../Context/useCreditRequest";
import TarsServices from "../Services/TarsServices";
import IntlTelInput from 'react-intl-tel-input-18';
import 'react-intl-tel-input-18/dist/main.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import CustomSelect from "./CustomSelect";
import { createRef } from "react";
import Privacy from "./Privacy";
import PersonalData from "./PersonalData";

// const age = (birthdate) => {
//     const today = new Date();
//     const age = today.getFullYear() - birthdate.getFullYear() -
//         (today.getMonth() < birthdate.getMonth() ||
//             (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate()));
//     return age;
// }

const esBisiesto = (year) => {
    return (year % 400 === 0) ? true :
        (year % 100 === 0) ? false :
            year % 4 === 0;
};


const RequestStepTwo = () => {


    const { setLogoSize, getEC, getCT } = useCreditRequest();
    const { getStorage, setStorage } = useCreditRequestAuth();

    let request = getStorage("request")

    const navigate = useNavigate();
    const initialForm = { firstName: request.cusFirstName ? request.cusFirstName : "", secondName: request.cusSecondName ? request.cusSecondName : "", firstSurname: request.cusFirstSurname ? request.cusFirstSurname : "", secondSurname: request.cusSecondSurname ? request.cusSecondSurname : "", year: request.year ? request.year : "", month: request.month ? request.month : "", day: request.day ? request.day : "", country: request.cusBirthCountry ? request.cusBirthCountry : (request.cusDocumentType === "CCC" ? "CO" : ""), nationality: request.cusNationality ? request.cusNationality : (request.cusDocumentType === "CCC" ? "CO" : ""), phoneCode: [{ value: "CO", name: "57" }], phone: request.cusPhone ? request.cusPhone : "", email: request.cusEmail ? request.cusEmail : "", gender: request.cusGender ? request.cusGender : null, dataPrivacyCheck: false, dataTreatmentCheck: false };
    const [sending, setSending] = useState(false);

    const firstNameRef = useRef(null);
    const firstSurnameRef = useRef(null);
    const secondSurnameRef = useRef(null);
    const phoneRef = useRef(null);
    const emailRef = useRef(null);
    const dayRef = useRef(null);
    const monthRef = useRef(null);
    const yearRef = useRef(null);



    const countryRef = createRef(null);

    const validateForm = (form) => {


        let errors = {};
        let regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!form.year.trim()) { errors.year = "El campo Año es requerido"; yearRef.current.focus(); }
        if (!form.month.trim()) { errors.month = "El campo Mes es requerido"; monthRef.current.focus(); }
        if (!form.day.trim()) { errors.day = "El campo Día es requerido"; dayRef.current.focus(); }

        if (form.day && form.month && form.year) {

            if (esBisiesto(form.year) && ('00' + form.month).slice(-2) === "02" && form.day > "29") { errors.day = "El campo Día es requerido" }

            if (!esBisiesto(form.year) && ('00' + form.month).slice(-2) === "02" && form.day > "28") { errors.day = "El campo Día es requerido" }


            if (["04", "06", "09", "11"].includes(('00' + form.month).slice(-2))) {
                if (form.day === "31") { errors.day = "El campo Día es requerido" }
            }

        }


        if (!form.dataTreatmentCheck) { errors.dataTreatmentCheck = "El campo dataTreatmentCheck es requerido" }
        if (!form.dataPrivacyCheck) { errors.dataPrivacyCheck = "El campo dataPrivacyCheck es requerido" }
        if (!form.gender) { errors.gender = "El campo Genero es requerido" }


        if (!form.email.trim() || !regexEmail.test(form.email.trim())) { errors.email = "El campo Correo electronico es requerido"; emailRef.current.focus(); }

        if (!form.phone.trim()) { errors.phone = "El campo Telefono es requerido"; phoneRef.current.tel.focus() }
        if (form.phoneCode.length === 0) { errors.phoneCode = "El campo Indicativo es requerido" }

        if (!form.nationality.trim()) { errors.nationality = "El campo Nacionalidad es requerido" }

        if (!form.country.trim()) { errors.country = "El campo Pais de nacimiento es requerido"; countryRef.current.focus(); }
        // if (request.cusDocumentType === "CCC" && (!form.secondSurname.trim() || form.secondSurname.length <= 1)) { errors.secondSurname = "El campo Segundo apellido es requerido"; secondSurnameRef.current.focus() }
        if (!form.firstSurname.trim() || form.firstSurname.length <= 1) { errors.firstSurname = "El campo Primer apellido es requerido"; firstSurnameRef.current.focus(); }
        if (!form.firstName.trim() || form.firstName.length <= 1) { errors.firstName = "El campo Primer nombre es requerido"; firstNameRef.current.focus(); }








        return errors;

    };

    const { form, errors, handleChange, handleChecked, handleSubmit, handleChangeDate, handleChangeTypeahead, handleChangeSelect } = useForm(initialForm, validateForm);
    const [countries, setCountries] = useState([]);
    const [phoneCodes, setPhoneCodes] = useState([]);
    // const countryRef = React.useRef();
    // const nationalityRef = React.useRef();
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const [genders, setGenders] = useState([]);
    const { newCustomer } = TarsServices();
    const handleClose = () => setShow(false);
    const handleClose1 = () => setShow1(false);
    const handleClose2 = () => setShow2(false);
    const handleShow = () => setShow(true);
    const handleShow1 = () => setShow1(true);
    const handleShow2 = () => setShow2(true);
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        setLogoSize({ width: "240px", marginTop: "40px", marginBottom: "30px" })
        setSending(true);

        getCT().then(data => {

            setSending(false);
            setCountries(data.map(el => { return { value: el.value, name: el.OriginalName } }))
            setPhoneCodes(data.map(el => { return { value: el.value, name: el.Phonecode } }))
        });

        getEC().then(data => {
            setGenders(data.filter(el => el.EnumerationId === "cusEnumGender")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label } }))

        })

    }, []);

    const changePhone = (status, value, countryData, number, id) => {
        value = value.replace(/[^\w\s]/gi, "").replace(/[a-z]/gi, '').replaceAll(" ", "")

        // let p1 = ""
        // let p2 = ""
        // let p3 = ""
        // let temp = ""

        // if (value.length <= 3) {
        //     temp = value
        // }

        // if (value.length === 4) {
        //     p1 = value.slice(0, 3);
        //     p2 = value.slice(3, 4);
        //     temp = `${p1} ${p2}`
        // }
        // if (value.length === 5) {
        //     p1 = value.slice(0, 3);
        //     p2 = value.slice(3, 5);
        //     temp = `${p1} ${p2}`
        // }
        // if (value.length === 6) {
        //     p1 = value.slice(0, 3);
        //     p2 = value.slice(3, 6);
        //     temp = `${p1} ${p2}`
        // }
        // if (value.length >= 7) {
        //     p1 = value.slice(0, 3);
        //     p2 = value.slice(3, 6);
        //     p3 = value.slice(6);
        //     temp = `${p1} ${p2} ${p3}`
        // }
        // if (value.length > 10) {
        //     return;
        // }



        handleChange({ target: { name: "phone", value: value } });
    }

    const changePhoneCode = (num, country) => {
        let phoneCode = phoneCodes.find(el => el.name === Number(country.dialCode))
        handleChangeTypeahead([phoneCode], "phoneCode")
    }

    const submitForm = (form, error) => {
        form.phone = form.phone.replaceAll(" ", "")

        if (!error) {

            setSending(true);

            let body =
            {
                cusType: "REG",
                cusDocumentType: request.cusDocumentType,
                cusDocument: request.cusDocument,
                cusFirstName: form.firstName,
                cusSecondName: form.secondName,
                cusFirstSurname: form.firstSurname,
                cusSecondSurname: form.secondSurname,
                cusGender: form.gender,
                cusBirthDate: form.year + "-" + ('00' + form.month).slice(-2) + "-" + ('00' + form.day).slice(-2),
                cusNationality: form.nationality,
                cusBirthCountry: form.country,
                cusEmail: form.email,
                cusPhoneCountry: form.phoneCode[0].value,
                cusPhone: form.phone,
                cusConditionsAcceptance: true
            }


            newCustomer(body).then(resCus => {

                if (resCus.status === 201) {

                    setSending(false);

                    let data = JSON.parse(resCus.data.data)

                    if (!data.ValidCommunication) {
                        setStorage("request", {
                            ...request,
                            cusPhone: form.phone,
                            cusPhoneCountry: form.phoneCode[0].value,
                            phoneCodeTemp: "+" + form.phoneCode[0].name,
                            cusEmail: form.email,
                            cusFirstName: form.firstName,
                            cusSecondName: form.secondName,
                            cusFirstSurname: form.firstSurname,
                            cusSecondSurname: form.secondSurname,
                            year: form.year,
                            month: form.month,
                            day: form.day,
                            cusNationality: form.nationality,
                            cusBirthCountry: form.country,


                            cusGender: form.gender,
                            state: 2
                        });

                        navigate("/DigitalConfirmation");
                    }

                    else {
                        setStorage("request", {
                            ...request,
                            cusPhone: form.phone,
                            cusPhoneCountry: form.phoneCode[0].value,
                            phoneCodeTemp: "+" + form.phoneCode[0].name,
                            cusEmail: form.email,
                            cusFirstName: form.firstName,
                            cusSecondName: form.secondName,
                            cusFirstSurname: form.firstSurname,
                            cusSecondSurname: form.secondSurname,
                            year: form.year,
                            month: form.month,
                            day: form.day,
                            cusNationality: form.nationality,
                            cusBirthCountry: form.country,


                            cusGender: form.gender,
                            state: 4
                        });

                        navigate("/Profile");
                    }




                }
                else {
                    setSending(false);
                    MySwal.fire({
                        html: resCus.data.message,
                        icon: 'error'
                    })
                }
            });





        }
        else {
            MySwal.fire({
                html: "Completa todos los campos",
                icon: 'warning'
            })
        }

    }

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e, submitForm)}>
                <table id="step-two-table">
                    <tbody>

                        <tr>
                            <td style={{ textAlign: "center", color: "gray", fontSize: "13px" }} colSpan={2}>{request.cusDocumentTypeTemp}   {request.cusDocument}</td>

                        </tr>
                        <tr>
                            <td style={{ textAlign: "center", color: "gray", padding: "0px" }} colSpan={2}>
                                <h3><b style={{ color: "black" }}>1</b>/4</h3>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div className="form-floating">
                                    <input ref={firstNameRef} type="text" name="firstName" value={form.firstName} onChange={handleChange} className={`form-control ${errors.firstName && "is-invalid"}`} placeholder="Primer nombre" autoComplete="off" />
                                    <label htmlFor="firstName">Primer nombre</label>
                                </div>

                            </td>
                            <td>
                                <div className="form-floating">
                                    <input type="text" name="secondName" value={form.secondName} onChange={handleChange} className={`form-control ${errors.secondName && "is-invalid"}`} placeholder="Segundo nombre" autoComplete="off" />
                                    <label htmlFor="secondName">Segundo nombre</label>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="form-floating">
                                    <input ref={firstSurnameRef} type="text" name="firstSurname" value={form.firstSurname} onChange={handleChange} className={`form-control ${errors.firstSurname && "is-invalid"}`} placeholder="Primer apellido" autoComplete="off" />
                                    <label htmlFor="firstSurname">Primer apellido</label>
                                </div>

                            </td>
                            <td>
                                <div className="form-floating">
                                    <input ref={secondSurnameRef} type="text" name="secondSurname" value={form.secondSurname} onChange={handleChange} className={`form-control ${errors.secondSurname && "is-invalid"}`} placeholder="Segundo apellido" autoComplete="off" />
                                    <label htmlFor="secondSurname">Segundo apellido</label>
                                </div>

                            </td>
                        </tr>
                        <tr>


                            <td colSpan={2} >





                                <CustomSelect ref={countryRef} name="country" value={form.country} options={countries} text="País de nacimiento" error={errors.country} handleChangeSelect={handleChangeSelect} />


                            </td>
                        </tr>

                        {request.cusDocumentType !== "CCC" && (<tr>
                            <td colSpan={2}>


                                <CustomSelect name="nationality" value={form.nationality} options={countries} text="Nacionalidad" error={errors.nationality} handleChangeSelect={handleChangeSelect} />

                            </td>
                        </tr>
                        )}





                        <tr>



                            <td colSpan={2}>

                                <IntlTelInput
                                    ref={phoneRef}
                                    containerClassName="intl-tel-input"
                                    inputClassName={`form-control ${errors.phone && "is-invalid"}`}
                                    placeholder="Teléfono"
                                    preferredCountries={[]}
                                    onPhoneNumberChange={changePhone}
                                    onSelectFlag={changePhoneCode}
                                    name="phone"
                                    defaultCountry="co"
                                    onlyCountries={["co"]}
                                    value={form.phone}
                                />



                            </td>

                        </tr>

                        <tr>
                            <td colSpan={2}>
                                <div className="form-floating" >
                                    <input ref={emailRef} type="email" name="email" value={form.email} onChange={handleChange} className={`form-control ${errors.email && "is-invalid"}`} placeholder="Correo electrónico" autoComplete="off" />
                                    <label htmlFor="email">Correo electrónico</label>
                                </div>

                            </td>
                        </tr>
                        <tr>


                            <td colSpan={2}>
                                <hr className="hr-text" data-content="Fecha de nacimiento"></hr>
                                <div style={{ display: "inline-flex", width: "100%" }}>

                                    <div className="form-floating" style={{ marginRight: "5px" }} >
                                        <input ref={dayRef} inputMode="numeric" type="number" name="day" value={form.day} onChange={handleChangeDate} placeholder="Dia" autoComplete="off" className={`form-control ${errors.day && "is-invalid"}`} min="1" max="31" />
                                        <label htmlFor="dia">Dia</label>
                                    </div>


                                    <div className="form-floating" >
                                        <input ref={monthRef} inputMode="numeric" type="number" name="month" value={form.month} onChange={handleChangeDate} placeholder="Mes" autoComplete="off" className={`form-control ${errors.month && "is-invalid"}`} min="1" max="12" />
                                        <label htmlFor="mes">Mes</label>
                                    </div>

                                    <div className="form-floating" style={{ marginLeft: "5px" }}>
                                        <input ref={yearRef} inputMode="numeric" type="number" name="year" value={form.year} onChange={handleChange} placeholder="Año" autoComplete="off" className={`form-control ${errors.year && "is-invalid"}`} min="1900" max={(new Date().getFullYear()) - 18} />
                                        <label htmlFor="anio">Año</label>
                                    </div>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <hr className="hr-text" data-content="Género"></hr>
                                <div className="container-fluid" id="divGeneros">
                                    <div className="row" style={{ textAlign: "center" }}>

                                        {
                                            genders.length > 0 && (
                                                genders.map(el => {
                                                    return (
                                                        <div className="col" key={el.value}>
                                                            <input type="radio" className="btn-check" name="gender" id={el.value} value={el.value} onChange={handleChange} autoComplete="off" required defaultChecked={el.value === request.cusGender} />
                                                            <label style={{ width: "100px" }} className="btn btn-outline-secondary" htmlFor={el.value}>{el.name}</label>
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </div>

                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={{ textAlign: "center" }}>

                                <div className="form-check" style={{ marginLeft: "10px" }}>
                                    <input className="form-check-input" type="checkbox" checked={form.dataPrivacyCheck} id="checkPrivacidad" name="dataPrivacyCheck" onChange={handleChecked} style={{ float: "initial", fontSize: "25px" }} />
                                    <label className="form-check-label" style={{ marginLeft: "10px", textAlign: "left" }}>
                                        Acepto la <u style={{ color: "blue", cursor: "pointer" }} onClick={handleShow1}>política de privacidad y tratamiento de datos personales</u>
                                        {/* Acepto la <a target="_blank" rel="noopener noreferrer" href="https://www.meddipay.com.co/privacy?section=policy">política de privacidad y tratamiento de datos personales</a> */}

                                    </label>
                                </div>


                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={{ textAlign: "center" }}>
                                <div className="form-check" style={{ marginLeft: "10px" }}>
                                    <input className="form-check-input" type="checkbox" checked={form.dataTreatmentCheck} id="checkTratamiento" name="dataTreatmentCheck" onChange={handleChecked} style={{ float: "initial", fontSize: "25px" }} />
                                    <label className="form-check-label" style={{ marginLeft: "10px", textAlign: "left" }}>
                                        Acepto el <u style={{ color: "blue", cursor: "pointer" }} onClick={handleShow}>Aviso de privacidad</u> y a ser contactado por los siguientes canales de cobranza:<br /> SMS, Correo electrónico, Llamada telefónica, Mensaje por aplicaciones
                                        {/* Acepto el <a target="_blank" rel="noopener noreferrer" href="https://www.meddipay.com.co/privacy?section=notice">Aviso de privacidad</a> y a ser contactado por los siguientes canales de cobranza:<br /> SMS, Correo electrónico, Llamada telefónica, Mensaje por aplicaciones */}
                                    </label>
                                </div>

                            </td>
                        </tr>

                        <tr>
                            <td style={{ textAlign: "center", color: "red" }} colSpan={2}>
                                {(errors.dataPrivacyCheck || errors.dataTreatmentCheck) && (<p>Debe aceptar las políticas</p>)}
                            </td>
                        </tr>


                        <tr>

                            <td style={{ textAlign: "center", padding: "10px" }} colSpan={2}>
                                <Link to="/" onClick={() => {
                                    setStorage("request", {
                                        ...request, state: 0
                                    })
                                }}>
                                    <button type="button" disabled={sending} className="btn btn-mp-back" style={{ marginRight: "20px" }}>Regresar</button>
                                </Link>

                                <input type="submit" value="Continuar" disabled={sending} className="btn btn-mp" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {sending &&
                                    (
                                        <div className="my-loader">
                                            <div className="my-loader-container">
                                                <img src={imgLoader} alt="loader"></img>

                                            </div>
                                        </div>
                                    )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>


            <Modal show={show} onHide={handleClose} dialogClassName="modal-aut">
                <Modal.Header closeButton>

                </Modal.Header>

                <Modal.Body style={{ padding: "0" }}>

                    <div style={{ margin: "0 auto", width: "100%", textAlign: "justify", overflowY: "scroll", height: "500px", padding: "30px" }}>
                        <Privacy />
                    </div>
                    
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { handleChecked({ target: { name: "dataTreatmentCheck", checked: true } }); handleClose() }}>He leido y acepto</Button>
                    <Button variant="secondary" onClick={() => { handleChecked({ target: { name: "dataTreatmentCheck", checked: false } }); handleClose() }}>No acepto</Button>
                </Modal.Footer>
            </Modal>




            <Modal show={show1} onHide={handleClose1} dialogClassName="modal-aut" centered>
                <Modal.Header closeButton>

                </Modal.Header>

                <Modal.Body style={{ padding: "0" }}>
                    <div style={{ margin: "0 auto", width: "100%", textAlign: "justify", overflowY: "scroll", height: "500px", padding: "30px" }}>

                        <PersonalData/>

                    </div>
                    
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { handleChecked({ target: { name: "dataPrivacyCheck", checked: true } }); handleClose1() }}>He leido y acepto</Button>
                    <Button variant="secondary" onClick={() => { handleChecked({ target: { name: "dataPrivacyCheck", checked: false } }); handleClose1() }}>No acepto</Button>
                </Modal.Footer>
            </Modal >


            {/* <Modal show={show2} onHide={handleClose2} dialogClassName="modal-aut">
                <Modal.Header closeButton>

                </Modal.Header>

                <Modal.Body>

                    <div style={{ margin: "0 auto", width: "100%", textAlign: "justify", overflowY: "scroll", height: "500px", padding: "30px" }}>

                        <p style={{ textAlign: "center" }}><b>AVISO DE PRIVACIDAD DE TRATAMIENTO DE DATOS PERSONALES</b>  </p>

                        <div className="container">


                            <div className="my-5">
                                <p></p>
                                <p>
                                    Meddipay S.A.S (En adelante Meddipay), identificada con NIT
                                    901763399-5, es responsable del tratamiento de los datos personales
                                    que son recolectados y almacenados en las bases datos. Su
                                    tratamiento se realiza conforme a su <a target="_blank" href="https://www.meddipay.com.co/privacy">Pol&iacute;tica de privacidad y
                                        tratamiento de datos personales</a>, respetando lo
                                    consagrado en la Ley 1581 de 2012 y dem&aacute;s normas
                                    concordantes.
                                </p>
                                <p></p>
                                <p className="text-mp-primary fs-3 fw-bold">Autorizaci&oacute;n</p>
                                <p></p>
                                <p>
                                    Esta es la autorizaci&oacute;n mediante la cual el usuario da su
                                    consentimiento previo, expreso e informado para que Meddipay
                                    almacene y procese sus datos personales, de acuerdo a la
                                    Pol&iacute;tica de privacidad y tratamiento de datos personales.
                                    
                                    Así mismo, el titular autoriza la transmisión y transferencia de sus datos a FIN TECHNOLOGIES SAS, con NIT 901.603.139-1, para el tratamiento de datos relacionado con consulta y reportes ante centrales de información financiera, en virtud al acuerdo de colaboración existente entre MEDDIPAY y FIN TECHNOLOGIES, para el perfilamiento y análisis de capacidad de endeudamiento de clientes de la plataforma MEDDIPAY, especialmente en lo relacionado con la finalidad #11 de clientes y usuarios.
                                </p>
                                <p></p>
                                <p className="text-mp-primary fs-3 fw-bold">Finalidades</p>
                                <p></p>
                                <p>
                                    Los datos ser&aacute;n almacenados y tratados por Meddipay, y sus
                                    terceros aliados, para las siguientes finalidades:
                                </p>
                                <p></p>
                                <p>
                                    1. Recolectar y almacenar la informaci&oacute;n en nuestras bases de
                                    datos.
                                </p>
                                <p>
                                    2. Dar cumplimiento a las obligaciones contra&iacute;das con
                                    nuestros usuarios y/o clientes.
                                </p>
                                <p>3. Prestar los servicios de forma correcta.</p>
                                <p>4. Ordenar y clasificar los datos personales suministrados.</p>
                                <p>
                                    5. Verificar los datos personales suministrados, para evitar fraudes
                                    o suplantaci&oacute;n de identidad.
                                </p>
                                <p>
                                    6. Creaci&oacute;n y gesti&oacute;n de los perfiles y/o cuentas de
                                    los usuarios y/o clientes.
                                </p>
                                <p>
                                    7. Mantener contacto y comunicaci&oacute;n con los titulares de los
                                    datos.
                                </p>
                                <p>
                                    8. Entablar comunicaci&oacute;n con los usuarios y/o clientes, a
                                    trav&eacute;s de llamadas, mensajes de texto, correo
                                    electr&oacute;nico o a trav&eacute;s de cualquier otro medio de
                                    comunicaci&oacute;n f&iacute;sico o digital.
                                </p>
                                <p>9. Para gesti&oacute;n administrativa, contable y fiscal.</p>
                                <p>
                                    10. Analizar el perfil crediticio y de riesgo de nuestros clientes
                                    y/o usuarios.
                                </p>
                                <p>
                                    11. Consultar a las centrales de informaci&oacute;n de riesgo,
                                    crediticia y comercial, el historial crediticio de los clientes y/o
                                    usuarios.
                                </p>
                                <p>
                                    12. Reportar a las centrales de riesgo incumplimiento de los
                                    usuarios y/o clientes de sus obligaciones financieras.
                                </p>
                                <p>
                                    13. Verificar el cumplimiento de las obligaciones de nuestros
                                    clientes y/o usuarios.
                                </p>
                                <p>
                                    14. Compartir con terceros, incluyendo la transferencia y
                                    transmisi&oacute;n (inclusive internacional), los datos personales
                                    de los usuarios y/o clientes.
                                </p>
                                <p>
                                    15. Recibir informaci&oacute;n de terceros sobre nuestros clientes
                                    y/o usuarios.
                                </p>
                                <p></p>
                                <p className="text-mp-primary fs-3 fw-bold">
                                    Datos personales almacenados y procesados
                                </p>
                                <p></p>
                                <p></p>
                                <p>
                                    Meddipay puede solicitar y almacenar datos necesarios para la
                                    correcta prestaci&oacute;n de sus servicios, tales como: 1) Datos de
                                    identificaci&oacute;n y de contacto, 2) informaci&oacute;n
                                    financiera (Ingresos, gastos, h&aacute;bitos), 3) informaci&oacute;n
                                    sobre profesi&oacute;n u oficio, 4) informaci&oacute;n
                                    demogr&aacute;fica, 5) datos sensibles.
                                </p>
                                <p></p>
                                <p className="text-mp-primary fs-3 fw-bold">Derechos del titular</p>
                                <p></p>
                                <p>Los titulares de los datos tienen los siguientes derechos:</p>
                                <p>
                                    1. Presentar solicitudes de actualizaci&oacute;n,
                                    informaci&oacute;n, eliminaci&oacute;n de sus datos personales.
                                </p>
                                <p>
                                    2. Ser informado del uso que hace Meddipay de sus datos personales.
                                </p>
                                <p>
                                    3. Presentar quejas ante las autoridades competentes por violaciones
                                    al r&eacute;gimen de protecci&oacute;n de datos.
                                </p>
                                <p>4. Revocar la autorizaci&oacute;n de tratamiento de datos.</p>
                                <p></p>
                                <p>
                                    Los clientes y/o usuarios solo podr&aacute;n revocar la
                                    autorizaci&oacute;n o suprimir los datos personales si no existe una
                                    obligaci&oacute;n legal o contractual que implique conservar los
                                    datos personales en las bases de datos de Meddipay.
                                </p>
                                <p></p>
                                <p></p>
                                <p className="text-mp-primary fs-3 fw-bold">
                                    Acceso a la informaci&oacute;n
                                </p>
                                <p></p>
                                <p>
                                    Puede tener acceso a los datos personales los titulares de la
                                    informaci&oacute;n (acreditando su identidad), los causahabientes
                                    (acreditando la calidad de causahabientes), los representantes o
                                    apoderados de los titulares de la informaci&oacute;n (acreditando la
                                    calidad de representante o apoderado)
                                </p>
                                <p></p>
                                <p>
                                    Los canales de atenci&oacute;n para acceso a la informaci&oacute;n
                                    son los siguientes:
                                </p>
                                <p></p>
                                <p>
                                    &middot; Atenci&oacute;n virtual <a data-fr-linked="true" href="mailto:hola@meddipay.com">hola@meddipay.com</a>
                                </p>
                                <p>
                                    &middot; Atenci&oacute;n presencial Carrera 28 C #71-28 Manizales
                                </p>
                            </div>
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { handleChecked({ target: { name: "dataPrivacyCheck", checked: true } }); handleClose2() }}>He leido y acepto</Button>
                    <Button variant="secondary" onClick={() => { handleChecked({ target: { name: "dataPrivacyCheck", checked: false } }); handleClose2() }}>No acepto</Button>
                </Modal.Footer>
            </Modal> */}


        </>

    );
}

export default RequestStepTwo;